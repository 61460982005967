import { useState, useEffect } from "react"
import SpotifyPlayer from "react-spotify-web-playback"

export default function Player({ accessToken, trackUri }) {
  const [play, setPlay] = useState(false)

  useEffect(() => setPlay(true), [trackUri])
  if (!accessToken) return null
  return (
    <SpotifyPlayer
      token={accessToken}
      showSaveIcon
      callback={state => {
        if (!state.isPlaying) setPlay(false)
      }}
      next
      previous
      play={play}
      uris={trackUri ? [trackUri] : []}
      styles={{
        activeColor: '#1DB954',
        bgColor: '#343a40',
        color: '#fff',
        loaderColor: '#1DB954',
        sliderColor: '#1DB954',
        trackArtistColor: '#ccc',
        trackNameColor: '#fff',
      }}
      className=""
    />
  )
}
