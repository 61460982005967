import { useState, useEffect } from "react";
import Player from "./Player";
import { Container, Form } from "react-bootstrap";
import SpotifyWebApi from "spotify-web-api-node";
import SearchResult from "./SearchResult";

const spotifyApi = new SpotifyWebApi({
  clientId: "e172ee2072d24570a27d8bf751bb696e",
});

export default function Dashboard({ handleLogout }) {
  // Clear hash from URL
  window.location.hash = "";

  // Get access token from local storage
  const accessToken = localStorage.getItem("access_token");

  // Set up state variables
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [playingTrack, setPlayingTrack] = useState();
  const [imageUrl, setImageUrl] = useState();

  // Set access token on API object when it changes
  useEffect(() => {
    if (accessToken) {
      spotifyApi.setAccessToken(accessToken);
    }
  }, [accessToken]);

  // Search for tracks and playlists when search term changes
  useEffect(() => {
    if (search && accessToken) {
      let cancel = false;

      // Search for playlists
      const searchPlaylists = async () => {
        try {
          const res = await spotifyApi.searchPlaylists(search, { limit: 3 });
          if (!cancel) {
            const playlistResults = res.body.playlists.items.map(
              ({ name, uri, images }) => ({
                type: "playlist",
                name,
                uri,
                coverImageUrl: images[0].url,
              })
            );
            return playlistResults;
          }
        } catch (error) {
          console.error(error);
        }
        return [];
      };

      // Search for tracks
      const searchTracks = async () => {
        try {
          const res = await spotifyApi.searchTracks(search, { limit: 5 });
          if (!cancel) {
            const trackResults = res.body.tracks.items.map(
              ({ name, uri, artists, album: { images } }) => ({
                type: "track",
                artist: artists[0].name,
                title: name,
                uri,
                albumUrl: images[0].url,
                BigImage: images[0].url,
              })
            );
            return trackResults;
          }
        } catch (error) {
          console.error(error);
        }
        return [];
      };

      // Combine results and update state
      Promise.all([searchTracks(), searchPlaylists()]).then((results) => {
        const [trackResults, playlistResults] = results;
        setSearchResults([...trackResults, ...playlistResults]);
      });

      return () => {
        cancel = true;
      };
    } else {
      setSearchResults([]);
    }
  }, [search, accessToken]);

  // Update playing track and image URL when user chooses a result
  const chooseTrack = (track) => {
    setPlayingTrack(track);
    setImageUrl(track.BigImage);
    setSearch("");
  };

  const choosePlaylist = (playlist) => {
    setPlayingTrack(playlist);
    setImageUrl(playlist.coverImageUrl);
    setSearch("");
  };

  return (
    <Container className="d-flex flex-column py-2" style={{ height: "98vh" }}>
      <button onClick={handleLogout} className="color mb-1 rounded-2 p-1">
        Logout
      </button>
      <Form.Control
        type="search"
        placeholder="Znajdz Następny Utwór"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="color"
      />
      {imageUrl && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-75%, -70%)",
            zIndex: -99,
          }}
        >
          <img
            src={imageUrl}
            alt="spotisong"
            style={{ width: "150%", height: "150%", filter: "blur(100px)" }}
          />
          <img className="managesize" src={imageUrl} alt="spotisong" />
        </div>
      )}
      <div className="flex-grow-1 my-2 slide" style={{ overflowY: "auto" }}>
        {searchResults.map((result) => {
          if (result.type === "track") {
            return (
              <SearchResult
                result={result}
                key={result.uri}
                chooseResult={chooseTrack}
              />
            );
          } else if (result.type === "playlist") {
            return (
              <SearchResult
                result={result}
                key={result.uri}
                chooseResult={choosePlaylist}
              />
            );
          }
          return null;
        })}
      </div>
      <div>
        <Player
          className="mb-5"
          accessToken={accessToken}
          trackUri={playingTrack?.uri}
        />
      </div>
    </Container>
  );
}
