import React from "react";

export default function SearchResult({ result, chooseResult }) {
  function handleChoose() {
    chooseResult(result);
  }

  return (
    <>
      <div
        className="d-flex mt-0 mb-0 align-items-center bg-dark"
        style={{ cursor: "pointer" }}
        onClick={handleChoose}
      >
        <img
          src={result.coverImageUrl || result.albumUrl}
          style={{ height: "64px", width: "64px" }}
          alt="spotiimage"
        />
        <div className="ml-3 margindown bg-dark">
          <div className="text-white bg-dark ps mt">
            {result.name || result.title}
          </div>
          <div className="text-white bg-dark ps">
            {result.owner || result.artist}
          </div>
        </div>
      </div>
      <div className="color" style={{ height: "5px" }}></div>
    </>
  );
}
