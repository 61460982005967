import { Container } from "react-bootstrap";

// const redirect_uri = "http://localhost:3000/";
const redirect_uri = "https://spotithing.web.app/";
const AUTH_URL = `https://accounts.spotify.com/authorize?client_id=e172ee2072d24570a27d8bf751bb696e&response_type=token&redirect_uri=${redirect_uri}&scope=streaming%20user-read-email%20user-read-private%20user-library-read%20user-library-modify%20user-read-playback-state%20user-modify-playback-state&show_dialog=false`;

const Login = () => {
  if (window.location.hash) {
    const access_token = window.location.hash.split("&")[0].split("=")[1];
    const expires_in = (Number(window.location.hash.split("&")[2].split("=")[1]) * 1000).toString();
    const created_at = new Date().getTime().toString();
    window.localStorage.setItem("access_token", access_token);
    window.localStorage.setItem("expires_in", expires_in);
    window.localStorage.setItem("created_at", created_at);
    window.location.href = redirect_uri;
  }
  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <a className="btn btn-success btn-lg" href={AUTH_URL}>
        Login With Spotify
      </a>
    </Container>
  );
};

export default Login;