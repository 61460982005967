import { useState, useEffect } from "react";
import Login from "./Login";
import Dashboard from "./Dashboard";
import { Container } from "react-bootstrap";

const TOKEN_KEY = "access_token";
const EXPIRES_IN_KEY = "expires_in";
const CREATED_AT_KEY = "created_at";

const App = () => {
  const [tokenData, setTokenData] = useState(null);

  const getTokenDataFromLocalStorage = () => {
    const token = window.localStorage.getItem(TOKEN_KEY);
    const expiresIn = window.localStorage.getItem(EXPIRES_IN_KEY);
    const createdAt = window.localStorage.getItem(CREATED_AT_KEY);

    if (token && expiresIn && createdAt) {
      const parsedExpiresIn = parseInt(expiresIn);
      const parsedCreatedAt = parseInt(createdAt);
      return { token, expiresIn: parsedExpiresIn, createdAt: parsedCreatedAt };
    }

    return null;
  };

  const removeTokenDataFromLocalStorage = () => {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(EXPIRES_IN_KEY);
    window.localStorage.removeItem(CREATED_AT_KEY);
  };

  const isTokenValid = (tokenData) => {
    const current_time = new Date().getTime();
    const difference = current_time - tokenData.createdAt;
    return difference < tokenData.expiresIn;
  };

  useEffect(() => {
    const tokenDataFromLocalStorage = getTokenDataFromLocalStorage();
    if (tokenDataFromLocalStorage && isTokenValid(tokenDataFromLocalStorage)) {
      setTokenData(tokenDataFromLocalStorage);
    } else {
      removeTokenDataFromLocalStorage();
    }
  }, []);

  const handleLogout = () => {
    removeTokenDataFromLocalStorage();
    setTokenData(null);
  };

  return (
    <div className="App">
      <header className="App-header">
        {!tokenData ? (
          <Container
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "100vh" }}
          >
            <Login />
          </Container>
        ) : (
          <Dashboard handleLogout={handleLogout} />
        )}
      </header>
    </div>
  );
};

export default App;
